export default {
  "B1": {
    "v": "y",
    "t": "s"
  },
  "C1": {
    "v": "z",
    "t": "s"
  },
  "A2": {
    "v": 34995,
    "t": "n",
    "z": "m/d/yyyy"
  },
  "B2": {
    "v": -3,
    "t": "n"
  },
  "C2": {
    "v": 2,
    "t": "n"
  },
  "A3": {
    "v": 35361,
    "t": "n",
    "z": "m/d/yyyy"
  },
  "B3": {
    "v": 2,
    "t": "n"
  },
  "C3": {
    "v": 3,
    "t": "n"
  },
  "A4": {
    "v": 44492,
    "t": "n",
    "z": "m/d/yyyy"
  },
  "B4": {
    "v": 3,
    "t": "n"
  },
  "C4": {
    "v": 4,
    "t": "n"
  }
}


export const DEFAULT_RANGE = "Sheet1!A1:C4";
