// @ts-nocheck
import React, { useCallback, useRef, useEffect, useMemo } from "react";

import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import FileUploadIcon from '@material-ui/icons/ArrowUpward'; //  //FileUpload';
import FileDownloadIcon from '@material-ui/icons/ArrowDownward'; //FileDownload';

import { createCellFromStringValue, RangeOverlay, AreaProps, SelectionArea, DateUtils, CommonUtils } from "@sheetxl/models";

import { Sheet, RangeOverlay, AreaProps, SelectionArea } from "@sheetxl/grid";

import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

// console.log("Sheet, React Version", React.version);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth:  0,
    '& path': {
        color: 'rgba(255, 255, 255, .9)'
    }
  },
}));

const exportToXLSX = function(wbCurrent, fileName='exported') {
  const promise = new Promise((resolve, reject) => {
    import('exceljs').then(function(Excel) {
      let workbook = new Excel.Workbook();

      Object.keys(wbCurrent._data.sheets).forEach(key => {
        let worksheet = workbook.addWorksheet(key);
        const sheet = wbCurrent._data.sheets[key];
        const cells = sheet.cells;
        Object.keys(cells).forEach(key => {
          let cell = worksheet.getCell(key);
          let cellJson = cells[key];

          cell.value = cellJson.v;
          cell.numFmt = cellJson.z;
        });
      });

      workbook.xlsx.writeBuffer().then(function(buffer) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        const blob = new Blob([buffer], {type: fileType});
        import('file-saver').then(function(FileSaver) {
          FileSaver.saveAs(blob, fileName + fileExtension);
          resolve(workbook);
        }).catch(function(error) {
          console.warn(error);
          reject(error);
        });
      }).catch(function(error) {
        console.warn(error);
        reject(error);
      });
    });
  });

  return promise;
}

const importFromXLSX = function(wbCurrent, file) {
  const promise = new Promise((resolve, reject) => {
    const promiseExcel = import('exceljs');
    const promiseReader = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = () => {
        reject(reader.error);
      }
    });

    Promise.all([promiseExcel, promiseReader]).then(function([Excel, buffer]) {
      let jsonWB = {
        sheets: {
        }
      }

      let workbook = new Excel.Workbook();
      workbook.xlsx.load(buffer).then(wb => {
        wb.eachSheet((sheet, id) => {
          let jsonSheet = jsonWB.sheets[sheet.name] = {};
          let jsonCells = jsonSheet.cells = {};

          sheet.eachRow((row, rowIndex) => {
            row.eachCell((cell, cellIndex) => {
              let jsonCell = jsonCells[cell.address] = {};
              jsonCell.v = cell.value;
              let type = cell.type;
              if (cell.type === Excel.ValueType.Formula) {
                jsonCell.f = cell.formula;
                jsonCell.v = cell.result;
                if (DateUtils.isValidDate(jsonCell.v)) {
                  type = Excel.ValueType.Date;
                } else if ((typeof jsonCell.v === 'string') || (jsonCell.v instanceof String)) {
                  type = Excel.ValueType.String;
                } else if (CommonUtils.isNumeric(jsonCell.v)) {
                  type = Excel.ValueType.Number;
                }
              }

              if (type === Excel.ValueType.Date) {
                jsonCell.t = 'n';
                jsonCell.v = DateUtils.toExcelDate(jsonCell.v);
              } else if (
                type === Excel.ValueType.String ||
                type === Excel.ValueType.SharedString ||
                type === Excel.ValueType.RichText ||
                type === Excel.ValueType.Hyperlink) {
                jsonCell.t = 's';
              } else if (type === Excel.ValueType.Boolean) {
                jsonCell.t = 'b';
              } else if (type === Excel.ValueType.Number) {
                jsonCell.t = 'n';
              } else {
                debugger;
              }
              // hmm. numFmt is 'derived'. Not able to find original source?
              if (cell.numFmt)
                jsonCell.z = cell.numFmt;
            });
          })
        });
        /// convert
        wbCurrent.setData(jsonWB);
        resolve(workbook);
      }).catch(error => {
        reject(error);
      });
    }).catch(error => {
      console.warn(error);
      reject(error);
    });

  });

  return promise;
}


export interface SheetPaneProps {
  selectedRanges?: any; // TODO - need to type these too
  // TODO - add SheetModel here
  multiSheetSource?: any; // TODO - need to type these too

  sheetRef?: any;

  onLoaded?: any;
}

const copyBounds = function (area: AreaProps): AreaProps {
  return {
    left: area.left,
    top: area.top,
    bottom: area.bottom,
    right: area.right,
  };
};

const rangeAsArray = function (range) {
  if (!range)
    return [];
  else if (range.asRanges)
    return range.asRanges();
  else if (Array.isArray(range))
    return range;

  return [range];
}


// TODO - Allow the model to be passed in. (Not managed here....)
const SheetPane: React.FC<SheetPaneProps> = (props) => {
  const {
    selectedRanges = undefined,
    multiSheetSource,
    sheetName,
    sheetRef,
    onLoaded,
    stateManager,
    ...rest
  } = props;

  const theme = useTheme();
  const classes = useStyles();

  // build a sheetTheme
  const sheetTheme = useMemo(function() {
      return {
          main : {
            background: theme.palette.background.default,
            textColor: theme.palette.text.primary,
            stroke: theme.palette.divider
            // fontFamily: string; // figure out from theme
            // fontSize: number; // figure out from theme
            // strokeWidth: number; // should this be here? not sure
        }
      }
  }, [theme]);

  const rangeOverlays = useMemo(function () {
    let retValue: RangeOverlay[];
    if (selectedRanges) {
      retValue = [];
      let key = 1002;

      rangeAsArray(selectedRanges.valRanges).forEach((range: IRange) => {
        retValue.push({
          stroke: "green",
          fill: "rgb(0, 255, 0, .05)",
          strokeStyle: "solid",
          strokeWidth: 2,
          key: key++,
          bounds: copyBounds(range),
        })
      });

      rangeAsArray(selectedRanges.titleRanges).forEach((range: IRange) => {
        retValue.push({
          stroke: "rgb(192, 53, 62, 1)",
          fill: "rgb(192, 53, 62, .05)",
          strokeStyle: "solid",
          strokeWidth: 2,
          key: key++,
          bounds: copyBounds(range),
        })
      });

      rangeAsArray(selectedRanges.xRanges).forEach((range: IRange) => {
        retValue.push({
          stroke: "purple",
          fill: "rgb(255, 0, 255, .05)",
          strokeStyle: "solid",
          strokeWidth: 2,
          key: key++,
          bounds: copyBounds(range),
        })
      });
      return retValue;
    }
  }, [selectedRanges],
  );

  const onSelectionUpdate = useCallback(
    (selections: SelectionArea[]) => {
      if (sheetRef && sheetRef.current)
        sheetRef.current.selections = selections;
    },
    [sheetRef],
  );

  const handleOnLoaded = useCallback(() => {
    if (onLoaded) onLoaded();
  }, [onLoaded]);


  const refSheetSourceUnlistener = useRef();
  useEffect(() => {
    if (refSheetSourceUnlistener.current)
      refSheetSourceUnlistener.current();

    if (stateManager && multiSheetSource) {
      multiSheetSource.addUpdateListener(function (updates) {
        console.log('on update');
        stateManager.saveState();
      });
    }
  }, [stateManager, multiSheetSource]);


  const sheetSource = useMemo(function () {
    return {
        save: function() {
           if (multiSheetSource && multiSheetSource.toJSON) {
             let sheets = Object.keys(multiSheetSource.toJSON().sheets);
             if (sheets.length > 0) {
               console.log(multiSheetSource.toJSON().sheets[sheets[0]].cells);
             }
           }
        },
        open: function() {
           import('@sheetxl/io').then(function(io) {
             console.log(io);
             // TODO - move to chart!
             // get points for model
             // get xml for
             // out for now
           });
        },
        getCellAt: function(column: number, row: number) {
            if (!multiSheetSource)
                return null;
            return multiSheetSource.getCellAt(sheetName, column, row);
        },
        setCells: function(cells) {
            let cellUpdates = [];
            if (cells) {
                for (let i=0; i<cells.length;i++)
                  cellUpdates.push({
                    sheetName: sheetName,
                    rowIndex: cells[i].rowIndex,
                    columnIndex: cells[i].columnIndex,
                    cell: cells[i].cell
                  });
            }
            multiSheetSource.setCells(cellUpdates);
        }
    }
  }, [multiSheetSource]);

  handleOnLoaded();

  const refSheetHandle = useRef();

  return (
    <Box {...rest} display="flex" flexDirection="column">
      <Toolbar
        variant="dense"
        style={{ background: "#3883fa", minHeight: "36px" }}
      >
        <Typography variant="h6" style={{ color: "rgba(255, 255, 255, .8)", flexGrow: 1}}>
          Data
        </Typography>
        <label htmlFor="upload">
          <input
            style={{ display: 'none' }}
            id="upload"
            name="upload"
            type="file"
            accept="*.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => {
              const file = e.target.files[0];
              const onFinish = function() {
                e.target.value = '';
                if (refSheetHandle.current.sheetRef) {
                  refSheetHandle.current.sheetRef.resetAfterIndices({ }, true);
                }
              }
              importFromXLSX(multiSheetSource, file).then((ws) => {
                console.log('good', ws);
                onFinish();
              }).catch((error) => {
                console.warn('error', error);
                onFinish();
              });
            }}
          />
          <Button
            disabled={false}
            variant="outlined"
            size="small"
            component="span"
            className={classes.button}
          >
            <FileUploadIcon color="primary" />
          </Button>
        </label>
        <div style={{ minWidth: '6px' }}/>
        <Button
          disabled={false}
          variant="outlined"
          size="small"
          onClick={(event) => { exportToXLSX(multiSheetSource) }}
          className={classes.button}
        >
          <FileDownloadIcon color="primary" />
        </Button>
      </Toolbar>
      <Sheet
        style={{
          flex: "1 1 100%",
        }}
        ref={refSheetHandle}
        className={classes.root}
        sheetTheme={sheetTheme}
        sheetSource={sheetSource}
        createCellFromString={createCellFromStringValue}
        rangeOverlays={rangeOverlays}
        onSelectionUpdate={onSelectionUpdate}
      />
      <Toolbar
        variant="dense"
        style={{ background: "#ebebeb", color: "#333", minHeight: "1.6em" }}
      >
        <Typography variant="subtitle1"></Typography>
      </Toolbar>
    </Box>
  );
};

export default SheetPane;
