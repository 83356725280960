import React from "react";
import { useState, useMemo, useEffect, useCallback, useRef } from "react";

import { hot } from "react-hot-loader";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";


import ToggleButton from '@material-ui/lab/ToggleButton';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';

import { ThemeWrapper } from "@sheetxl/chart";

import { DAGM } from "@sheetxl/models";

import { DocumentTheme } from "@sheetxl/models"
import { ChartShape } from "@sheetxl/models";
import { SimpleMultiSheetSource } from "@sheetxl/models";

import { createRangeSelection } from "@sheetxl/models";
import { CellRange } from "@sheetxl/models";
import { RangeUtils } from "@sheetxl/models";

import { MultiSheetModel } from "@sheetxl/models";

import { PresetEditor } from "@sheetxl/chart";
import { DynamicShapePropertyEditor } from "@sheetxl/chart";
import { useDAGMProperty } from "@sheetxl/chart";

import { ChartShapeComponent } from "@sheetxl/chart";
import { DefaultTheme } from "@sheetxl/chart";

import JSONChartShapeEditor from "./JSONChartShapeEditor";

import DynamicPane from "./applets/DynamicPane";
import SheetPanel from "./applets/SheetPanel";

import Data, { DEFAULT_RANGE } from "./data/simpleDates"; // simpleMulti"; // scenario; // "./data/largeSet"; // "/.data/skippedDates"; // "./data/simpleScatter.js";  // "./data/xAxis"; // "./data/simpleData"; //  "./data/skippedDates"; // "./data/skippedDates";// "./data/simpleDates"; // "./data/multiLevel"; // "./data/multiRange"; // "./data/fromDemo";// "./data/simpleDates"; // "./data/simpleDates2";  //  "./data/skippedCol"; //

import "./App.css";

// import { loadFont } from "./Font";

const CHART_PANES = 2;


// Hook
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

// loadFont('comicsansms.ttf', 48);
// loadFont('candara.ttf', 48);

function App(props) {
  // TODO - This will need to support multiple layouts
  const [appState, setAppState] = useLocalStorage("app", { layouts : [{}]});
  const [darkModeOverride, setDarkModeOverride] = useState(function() {
      return appState.darkMode;
  });
  const [isDarkMode, setDarkMode] = useState(darkModeOverride);

  const handleThemeChange = function(theme) {
      setDarkMode(theme.palette.type === 'dark');
  }

  const handleDarkModeOverride = function(value) {
      let newDarkMode = !isDarkMode;
      const newAppState = {...appState};
      newAppState.darkMode = newDarkMode;
      setAppState(newAppState);
      setDarkModeOverride(newDarkMode);
  }

  const themeCustomizations = {
    //   shape: {
    //       borderRadius: '10px'
    //   },
    // palette: {
    //   primary: {
    //       main: '#00FF00'
    //   },
    // },
    // overrides: {
    //   SmartInputField: {
    //       automatic: {
    //           color: '#FF0000'
    //       },
    //       dirty: {
    //           color: '#0000FF'
    //       }
    //   }
    // }
  }

  const multiSheetSource = useMemo(() => {
    const initialData = Data;

    let multiSheet = { sheets: {}};
    multiSheet.sheets['Sheet1'] = {
      cells: initialData
    };
    return new SimpleMultiSheetSource(multiSheet);
  }, []);


  const [renderedRange, setRenderedRange] = useState(() => {
    return DEFAULT_RANGE;
  });

  const refSelection = useRef({
    selections: [],
  });

  const layoutEffective = useMemo(() => {
    const retValue = { ...appState.layouts };
    for (let i = retValue.length - 1; i < CHART_PANES; i++) retValue.push({});
    return retValue;
  }, [appState]);

  const getDefaultChartTile = function(chartShape) {
    return null;
    return 'Charting Title';
  }

  const getRowLabel = function(sheetName, rowOffset) {
    return "Row " + (rowOffset + 1);
  }

  const getColLabel = function(sheetName, colOffset) {
    return "Column " + RangeUtils.encode_col(colOffset);
  }

  const getDefaultSeriesLabel = function(seriesValRange, _seriesOffset, _seriesCount) {
    return null;
    const orientation = seriesValRange.height === 1 ? 'r' : 'c';
    if (orientation === 'r') {
        return getRowLabel(seriesValRange.sheetName, seriesValRange.top);
    }

    return getColLabel(seriesValRange.sheetName, seriesValRange.left);
  };

  // All axis are based on only the first series
  const getDefaultAxisLabel = function(pointRange, seriesValRange, axisLabelOffset, _axisLabelCount) {
//     if (axisLabelOffset === 1)
        return null;
    const orientation = seriesValRange.height === 1 ? 'r' : 'c';
    if (orientation === 'c') {
        return getRowLabel(seriesValRange.sheetName, pointRange.top);
    }

    return getColLabel(seriesValRange.sheetName, pointRange.left);
  };

  const [chartShapes, setChartShapes] = useState(() => {
    // Generate multiple chart shapes
    let retValue = [];
    for (let i = 0; i < CHART_PANES; i++) {
      let chartShape = null;
      if (renderedRange) {
        var startDate = new Date();
        console.log("generating chart");
        chartShape = DAGM.newFromJSON(layoutEffective[i], () => {
          return new ChartShape({
              range: renderedRange,
              sheet: new MultiSheetModel(multiSheetSource),
              docTheme: documentTheme,
              appContext: 'excel',
              getDefaultSeriesLabel : getDefaultSeriesLabel,
              getDefaultAxisLabel: getDefaultAxisLabel,
//               getDefaultChartTile: getDefaultChartTile
          });
        });
        console.log(
          "generated chart : ",
          (new Date().getTime() - startDate.getTime()) / 1000,
        );
      }

      retValue.push(chartShape);
    }
    return retValue;
  });


  const [themeDefinition, setThemeDefinition] = useState(() => {
    return DefaultTheme;
  });

  const documentTheme = useMemo(() => {
    // TODO - save to save after editor
    const themeLoaded = {
    };
    const newTheme = DAGM.newFromJSON(themeLoaded, () => {
      return new DocumentTheme({themeDef: themeDefinition});
    });

    return newTheme
  }, [themeDefinition]); // appState

  useEffect(() => {
    for (let i=0; i<chartShapes.length; i++) {
        chartShapes[i].docTheme = documentTheme;
    }
  }, [documentTheme]);

  const onDocumentThemeUpdate = function(documentThemeDefinition) {
    setThemeDefinition(documentThemeDefinition);
  }

  const [selectedIndex, setSelectedIndex] = useState(() => {
    return 0;
  });

  const [selectionSummary] = useDAGMProperty(chartShapes ? chartShapes[selectedIndex]: null, 'selectionSummary');

  const handleJsonChange = (change, range) => {
    let newChart = DAGM.newFromJSON(change, () => {
      return new ChartShape({
          range: range ? range.toString() : renderedRange,
          sheet: new MultiSheetModel(multiSheetSource),
          docTheme: documentTheme,
          appContext: 'excel',
          getDefaultSeriesLabel : getDefaultSeriesLabel,
          getDefaultAxisLabel: getDefaultAxisLabel,
          // getDefaultChartTile: getDefaultChartTile
      });
    });
    chartShapes[selectedIndex] = newChart;
    setChartShapes([...chartShapes]);
    layoutEffective[selectedIndex] = change;
    let newAppState = {...appState};
    newAppState.layouts = layoutEffective;
    setAppState(newAppState);
  };

  const handleJsonChangeWithSelect = (change) => {
    let ranges = [];
    if (refSelection.current.selections) {
        for (let i=0; i<refSelection.current.selections.length; i++) {
            ranges.push(new CellRange(
                refSelection.current.selections[i].bounds.left,
                refSelection.current.selections[i].bounds.top,
                refSelection.current.selections[i].bounds.right,
                refSelection.current.selections[i].bounds.bottom,
                'Sheet1'));
        }
    }
    let selectionRange = createRangeSelection(ranges, false/*singleLength*/, true/*alignRanges*/);
    setRenderedRange(selectionRange);
    handleJsonChange(change, selectionRange);
  };

  const handleKeyDown = useCallback((event) => {
    if (event.ctrlKey && event.shiftKey && event.key === "F") {
      console.log(document.activeElement);
    }
  });

  const chartShapesComponents = useMemo(() => {
      let retValue = [];
      if (chartShapes.length === 0) retValue = <></>;
      else {
        for (let i = 0; i < chartShapes.length; i++) {
          let handleChartFocus = (event) => {
            setSelectedIndex(i);
          };
          if (chartShapes[i]) {
            retValue.push(
              <Paper
                key={i}
                elevation={2}
                style={{ background: "white", flex: "1 1 0" }}
              >
                <ChartShapeComponent
                  chartModel={chartShapes[i]}
                  initialAnimation={0}
                  onFocus={handleChartFocus}
                />
              </Paper>,
            );
          } else {
            retValue.push(
              <Paper
                key={i}
                elevation={2}
                style={{ background: "white", flex: "1 1 0" }}
              >
                Empty
              </Paper>,
            );
          }
          if (i < chartShapes.length - 1)
            retValue.push(
              <Box key={i + "-div"} style={{ minHeight: "16px" }}></Box>,
            );
        }
      }
      return retValue;
  }, [chartShapes]);


  return (
    <ThemeWrapper darkMode={darkModeOverride} onChange={handleThemeChange} customizations={themeCustomizations} globalCssReset={true}>
        <Box
          display="flex"
          flexDirection="row"
          style={{ postion: "absolute", height: "100%", width: "100%" }}
          onKeyDown={handleKeyDown}
          // onMouseMove={e => {
          //   console.log(e.clientX, e.clientY, e.isDefaultPrevented(), e.isPropagationStopped);
          // }}
        >
          <Box display="flex" flexDirection="column" flexBasis="25%">
            <Paper elevation={2} style={{ margin: "8px", padding: "16px", display: "flex", alignItems: "center" }}>
              <PresetEditor
                chartShape={
                  chartShapes[selectedIndex] ? chartShapes[selectedIndex] : null
                }
                onSelect={handleJsonChangeWithSelect}
              />
              <Box display="flex" flexDirection="column" flex="1 1 100%"></Box>
              <ToggleButton
                  value="check"
                  selected={isDarkMode}
                  onChange={(event) => {
                    handleDarkModeOverride(event);
                  }}
               >
                  { isDarkMode ? <Brightness4Icon/> :  <Brightness7Icon/> }
              </ToggleButton>
              <Box width="20px"></Box>
            </Paper>
            <Paper  elevation={2} style={{display: "flex", flex: "1", margin: "8px", overflow: 'hidden'}}>
            <DynamicPane
                title="Chart"
                docTheme={documentTheme}
                onThemeUpdate={onDocumentThemeUpdate}>
              <DynamicShapePropertyEditor
                shape={chartShapes[selectedIndex]}
              />
            </DynamicPane>
            </Paper>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="35%"
            style={{ margin: "8px" }}
          >
            {/* <LazyAccordion title ="Chart Shape" unloadOnClose={true} expanded={false} > */}
            {chartShapesComponents}
            {/* </LazyAccordion> */}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            flexBasis="45%"
            style={{ margin: "8px", width: "0px", minHeight: "300px" }}
          >
            <Paper
              elevation={2}
              style={{
                display: "flex",
                flexGrow: 1,
                position: "relative",
                overflow: "hidden",
              }}
            >
              {/* <LazyAccordion unloadOnClose={true} expanded={true} > */}
              <SheetPanel
                style={{ flex: "1 1 100%" }}
                sheetName={'Sheet1'}
                multiSheetSource={multiSheetSource}
                sheetRef={refSelection}
                selectedRanges={selectionSummary}
              />
              {/* </LazyAccordion> */}
            </Paper>
            <Paper
              elevation={2}
              style={{ marginTop: "8px", display: "flex", flexGrow: 1 }}
            >
              <Box style={{ marginTop: "8px" }}></Box>
              {chartShapes[selectedIndex] ? (
                <JSONChartShapeEditor
                  style={{ overflow: "hidden", flexGrow: 1 }}
                  chartModel={chartShapes[selectedIndex]}
                  onModelChange={handleJsonChangeWithSelect}
                ></JSONChartShapeEditor>
              ) : (
                <Box>Nothing selected</Box>
              )}
            </Paper>
          </Box>
        </Box>
    </ThemeWrapper>
  );
}

//declare let module: Record<string, unknown>;

export default hot(module)(App);
