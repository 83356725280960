import React from "react";

import Paper from "@material-ui/core/Paper";

import { useDAGMProperty } from "@sheetxl/chart";

import JSONMonacoEditor from "./JSONMonacoEditor";

const JSONChartShapeEditor = (props) => {
  const { chartModel, onModelChange, ...others } = props;

  const [json] = useDAGMProperty(chartModel, "*", function (value) {
    return value.toJSON(false);
  });

  const handleChange = (change) => {
    if (onModelChange) onModelChange(change);
  };

  return (
    <Paper {...others} elevation={2}
      style={{
        overflow: "hidden",
        flex: "1 1 100%",
        height: "100%",
        display: "flex"
      }}
    >
      <JSONMonacoEditor
        jsonModel={json}
        onModelChange={handleChange}
      />
    </Paper>
  );
};

JSONChartShapeEditor.propTypes = {};

export default JSONChartShapeEditor;
