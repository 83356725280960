import React, { useEffect, useState, useRef, useCallback } from "react";

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import SaveIcon from '@material-ui/icons/Save';

import useMeasure from "react-use/esm/useMeasure";

import MonacoEditor from "@monaco-editor/react";
//import { useMonaco } from "@monaco-editor/react";

//https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.ieditoroptions.html#acceptsuggestiononcommitcharacter
const options = {
  acceptSuggestionOnCommitCharacter: true,
  acceptSuggestionOnEnter: 'on',
  accessibilitySupport: 'auto',
  autoIndent: false,
  automaticLayout: true,
  codeLens: true,
  colorDecorators: true,
  contextmenu: true,
  cursorBlinking: 'blink',
  cursorSmoothCaretAnimation: false,
  cursorStyle: 'line',
  disableLayerHinting: false,
  disableMonospaceOptimizations: false,
  dragAndDrop: true,
  fixedOverflowWidgets: false,
  folding: true,
  foldingStrategy: 'auto',
  fontLigatures: false,
  formatOnPaste: true,
  formatOnType: false,
  hideCursorInOverviewRuler: false,
  highlightActiveIndentGuide: true,
  links: true,
  mouseWheelZoom: false,
  multiCursorMergeOverlapping: true,
  multiCursorModifier: 'alt',
  overviewRulerBorder: true,
  overviewRulerLanes: 2,
  quickSuggestions: true,
  quickSuggestionsDelay: 100,
  readOnly: false,
  renderControlCharacters: false,
  renderFinalNewline: true,
  renderIndentGuides: true,
  renderLineHighlight: 'all',
  renderWhitespace: 'none',
  revealHorizontalRightPadding: 30,
  roundedSelection: true,
  rulers: [],
  scrollBeyondLastColumn: 5,
  scrollBeyondLastLine: true,
  selectOnLineNumbers: true,
  selectionClipboard: true,
  selectionHighlight: true,
  showFoldingControls: 'mouseover',
  smoothScrolling: true,//false,
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: true,
  // eslint-disable-next-line
  wordSeparators: `~!@#$%^&*()-=+[{]}\|;:'",.<>/?`,
  wordWrap: 'off',
  wordWrapBreakAfterCharacters: '\t})]?|&,;',
  wordWrapBreakBeforeCharacters: '{([+',
  wordWrapBreakObtrusiveCharacters: '.',
  wordWrapColumn: 80,
  wordWrapMinified: true,
  wrappingIndent: 'none',
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    '& path': {
        color: 'rgba(255, 255, 255, .9)'
    }
  },
}));

function JSONMonacoEditor(props) {
  const {
    jsonModel,
    onModelChange,
    ...rest
  } = props;
  const theme = useTheme();
  const classes = useStyles();

  const modelAsString = JSON.stringify(jsonModel, null, 2);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const dirtyRef = useRef();

  const isMountRef = useRef(true);
  const modelSet = useRef();
  const monacoRef = useRef(null);

  // Note - These three effects are a fragile way to set dirty on modelupdatefrom monaco
  // but no on load or from model prop changes.
  useEffect(() => {
    if (!isMountRef.current) {
        //console.log('jsonModel changes', jsonModel);
        setIsDirty(false);
    }
  }, [modelAsString]);

  dirtyRef.current = isDirty;
  useEffect(() => {
    //console.log('setting mounted', isMountRef.current, jsonModel);
    isMountRef.current = false;
  }, []);

  useEffect(() => {
    setIsDirty(dirtyRef.current);
  }, [dirtyRef.current]);

  function allowSave() {
      return monacoRef.current && dirtyRef.current && isValid;
  }

  function handleEditorValidation(markers) {
    setIsValid(markers.length === 0);

    //markers.forEach(marker => console.log("onInValidJson:", marker.message));
  }

  function handleEditorWillMount(monaco) {
    //console.log('handleEditorWillMount');
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
    });
  }

  const saveModel = useCallback(function () {
    if (!allowSave())
      return;

    //console.log('save', editor.getValue());
    // fire save event
    if (onModelChange) {
      onModelChange(JSON.parse(monacoRef.current.editor.getValue()));
    }
    setIsDirty(false);
  }, [onModelChange, isValid]);

  const handleEditorDidMount = useCallback(function (editor, monaco) {
      monacoRef.current = { monaco, editor };

      editor.addAction({
        id: "save",
        label: "Save to Model",
        keybindings: [
          // eslint-disable-next-line no-bitwise
          monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, // Ctrl + S or Cmd + S
        ],
        run: function(event) {
          //  console.log(editor.getValue());
          saveModel();
        }
      });
      editor.getModel().onDidChangeContent((event) => {
        //console.log('changed', event.isFlush);
        setIsDirty(true);
        setIsValid(false); // we mark as invalid because the async validation will mark as valid (if it is)
      });
  }, [saveModel]);

  const [refContainerMain, { width, height }] = useMeasure();
  return (
    <Box {...rest} display="flex" flexDirection="column" width="100%" flexGrow="1">
      <Toolbar
        variant="dense"
        style={{ background: "#3883fa", minHeight: "36px" }}
      >
        <Typography variant="h6" style={{ color: "rgba(255, 255, 255, .8)", flexGrow: 1, fontStyle: isDirty ? 'italic' : 'normal' }}>
          Model{ isDirty ? '*' : ''}
        </Typography>
        <Button
          disabled={!allowSave()}
          variant="outlined"
          size="small"
          onClick={(event) => {saveModel(event)}}
          className={classes.button}
          startIcon={<SaveIcon color="primary" />}
        >
          Save
        </Button>
      </Toolbar>
        <Box
        style={{
          flex: "1 1 100%",
        }}>
        <div ref={refContainerMain} style={{width: "100%", height: "100%", display:"block"}}>
          <div style={{width: width, height: height, position:"absolute", overflow: "hidden"}}>
        <MonacoEditor
              theme={theme.palette.type === 'dark' ? "vs-dark" : "vs-light"}
              options={options}
              language="json"
              value={modelAsString}
              onValidate={handleEditorValidation}
              beforeMount={handleEditorWillMount}
              onMount={handleEditorDidMount}
        />
          </div>
        </div>
        </Box>
      <Toolbar
        variant="dense"
        style={{ background: "#ebebeb", color: "#333", minHeight: "1.6em" }}
      >
        <Typography variant="subtitle1"
            style={{ flexGrow: 1, textAlign: 'right', fontSize: '12px' }}
        >
          { isDirty ? 'Unsaved' : ''}
        </Typography>
      </Toolbar>
    </Box>

  );
}

export default JSONMonacoEditor;