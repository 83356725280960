
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import { DocumentThemePropertyEditor } from "@sheetxl/chart";
import { DocThemeContext } from "@sheetxl/chart";
import { ThemeSelect } from "@sheetxl/chart";

import {default as ThemeList} from "../themes/ThemeList";


const useStyles = makeStyles((theme) => ({
  headingDynamicPane: {
    fontSize: theme.typography.pxToRem(18),
    margin: theme.spacing(1),
    paddingLeft: "8px",
    paddingRight: "8px",
    flex: "0 0 0",
  },
  fullFlex : {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",

  },
  tabContainer : {
    '& > div[hidden]' : {
      display: 'none'
    }
  },
  tabPane : {
    padding: "0px 0px",
  },
  tabIndicator : {
    background: theme.palette.primary.light,
  },
  themeEditor: {
    paddingTop: "0px"
  },
}));

export interface DynamicPaneProps {
  title?: any;
  children? :any;
  docTheme: any;
  onThemeUpdate: any;
  inputWidth?: string;
}

const DynamicPane: React.FC<DynamicPaneProps> = (props) => {
  const {
    title,
    docTheme,
    children,
    inputWidth = "60",
    onThemeUpdate,
    ...rest
  } = props;

  const classes = useStyles({ inputWidth: inputWidth });
  const themesAvailable = [];

  for (let i=0; i< ThemeList.length; i++) {
      themesAvailable.push(ThemeList[i]);
  }
  const [selectedTheme, setSelectedTheme] = useState((docTheme || themesAvailable[0]));

  const [selectedTab, setSelectedTab] = useState('1');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSelectTheme = function(theme, event, index) {
    setSelectedTheme(theme);
    if (onThemeUpdate)
        onThemeUpdate(theme, event, index);
  }

  return (
    <Box {...rest} className={clsx(classes.fullFlex, classes.tabContainer)}>
      <TabContext value={selectedTab} >
      {/* <AppBar position="static"> */}
      <Paper square>
        <TabList onChange={handleTabChange} aria-label="Property Tabs" variant="fullWidth"
          TabIndicatorProps={{
            className:clsx(classes.tabIndicator)
          }}>
          <Tab label="Properties" value="1"  color="primary"/>
          <Tab label="Theme" value="2" />
        </TabList>
      {/* </AppBar> */}
      </Paper>
      <TabPanel value="1"
        className={clsx(classes.fullFlex, classes.tabPane)}>
        { title ? (<>
        <Typography className={classes.headingDynamicPane}>
          { title }
        </Typography>
        <Divider light />
        </>) : <></> }
        <DocThemeContext.Provider value={docTheme}>
          {children}
        </DocThemeContext.Provider>
      </TabPanel>
      <TabPanel value="2"
        className={clsx(classes.fullFlex, classes.tabPane)}>
        { title ? (<>
        <Typography className={classes.headingDynamicPane}>
          Theme Configurations
        </Typography>
        <Divider light />
        </>) : <></> }
        <ThemeSelect
          themes={themesAvailable}
          selectedTheme={selectedTheme}
          onThemeUpdate={handleSelectTheme}
        />
        <DocumentThemePropertyEditor
          className={classes.themeEditor}
          model={
            docTheme
          }
        />
      </TabPanel>
    </TabContext>
    </Box>
  );
};

export default DynamicPane;
