export default [
// ['name', 'dk1', 'lt1', 'dk2', 'lt2', 'accent1', 'accent2', 'accent3', 'accent4', 'accent5', 'accent6', 'hlink', 'folHlink']

//   ["StandArd", "88BDE6", "FBB258", "90CD97", "F6AAC9", "BFA554", "BC99C7", "EDDD46", "F07E6E", "8C8C8C"],
//   ["CocoA", "466BB0", "C8B422", "14886E", "B54836", "6E5944", "8B3872", "73B22B", "B87320", "141414"],
//   ["CorAl", "84D0E0", "F48256", "95C78C", "EFA5D6", "BA8452", "AB95C2", "EDE9D0", "E96B7D", "888888"],
//   ["Dark", "005FAD", "F06400", "009330", "E400B1", "B65800", "6A279C", "D5A211", "DC0127", "000000"],
//   ["HighContrast", "FF82B0", "0DDA2C", "0021AB", "BCF28C", "19C23B", "890D3A", "607EFD", "1B7700", "000000"],
//   ["Light", "DDCA9A", "778DEB", "CB9FBB", "B5EAE2", "7270BE", "A6C7A7", "9E95C7", "95B0C7", "9B9B9B"],
//   ["Midnight", "83AACA", "E37849", "14A46A", "E097DA", "A26D54", "A584B7", "D89C54", "E86996", "2C343B"],
//   ["Modern", "2D9FC7", "EC993C", "89C235", "E377A4", "A68931", "A672A6", "D0C041", "E35855", "68706A"],
//   ["Organic", "9C88D9", "A3D767", "8EC3C0", "E9C3A9", "91AB36", "D4CCC0", "61BBD8", "E2D76F", "80715A"],
//   ["Slate", "7493CD", "F99820", "71B486", "E4A491", "CB883B", "AE83A4", "BACC5C", "E5746A", "505D65"],
//   ["Zen", "7BB5AE", "E2D287", "92B8DA", "EAC4CB", "7B8BBD", "C7D189", "B9A0C8", "DFB397", "A9A9A9"],
//   ["Cyborg", "2A9FD6", "77B300", "9933CC", "FF8800", "CC0000", "00CCA3", "3D6DCC", "525252", "000000"],
//   ["Superhero", "5CB85C", "F0AD4E", "5BC0DE", "D9534F", "9F5BDE", "46DB8C", "B6B86E", "4E5D6C", "2B3E4B"],
//   ["Flatly", "18BC9C", "3498DB", "F39C12", "6CC1BE", "99A549", "8F54B5", "E74C3C", "8A9899", "2C3E50"],
//   ["Darkly", "375A7F", "00BC8C", "3498DB", "F39C12", "E74C3C", "8F61B3", "B08725", "4A4949", "000000"],
//   ["Cerulan", "033E76", "87C048", "59822C", "53B3EB", "FC6506", "D42323", "E3BB00", "CCCCCC", "222222"],

  ["Office", "windowText", "window", "44546A", "E7E6E6", "4472C4", "ED7D31", "A5A5A5", "FFC000", "5B9BD5", "70AD47", "0563C1", "954F72"],
  ["Office 2007-2010", "windowText", "window", "1F497D", "EEECE1", "4F81BD", "C0504D", "9BBB59", "8064A2", "4BACC6", "F79646", "0000FF", "800080"],
  ["Grayscale", "windowText", "window", "000000", "F8F8F8", "DDDDDD", "B2B2B2", "969696", "808080", "5F5F5F", "4D4D4D", "5F5F5F", "919191"],
  ["Blue Warm", "windowText", "window", "242852", "ACCBF9", "4A66AC", "629DD1", "297FD5", "7F8FA9", "5AA2AE", "9D90A0", "9454C3", "3EBBF0"],
  ["Blue", "windowText", "window", "17406D", "DBEFF9", "0F6FC6", "009DD9", "0BD0D9", "10CF9B", "7CCA62", "A5C249", "F49100", "85DFD0"],
  ["Blue II", "windowText", "window", "335B74", "DFE3E5", "1CADE4", "2683C6", "27CED7", "42BA97", "3E8853", "62A39F", "6EAC1C", "B26B02"],
  ["Blue Green", "windowText", "window", "373545", "CEDBE6", "3494BA", "58B6C0", "75BDA7", "7A8C8E", "84ACB6", "2683C6", "6B9F25", "9F6715"],
  ["Green", "windowText", "window", "455F51", "E3DED1", "549E39", "8AB833", "C0CF3A", "029676", "4AB5C4", "0989B1", "6B9F25", "BA6906"],
  ["Green Yellow", "windowText", "window", "455F51", "E2DFCC", "99CB38", "63A537", "37A76F", "44C1A3", "4EB3CF", "51C3F9", "EE7B08", "977B2D"],
  ["Yellow", "windowText", "window", "39302A", "E5DEDB", "FFCA08", "F8931D", "CE8D3E", "EC7016", "E64823", "9C6A6A", "2998E3", "7F723D"],
  ["Yellow Orange", "windowText", "window", "4E3B30", "FBEEC9", "F0A22E", "A5644E", "B58B80", "C3986D", "A19574", "C17529", "AD1F1F", "FFC42F"],
  ["Orange", "windowText", "window", "637052", "CCDDEA", "E48312", "BD582C", "865640", "9B8357", "C2BC80", "94A088", "2998E3", "8C8C8C"],
  ["Orange Red", "windowText", "window", "696464", "E9E5DC", "D34817", "9B2D1F", "A28E6A", "956251", "918485", "855D5D", "CC9900", "96A9A9"],
  ["Red Orange", "windowText", "window", "505046", "EEECE1", "E84C22", "FFBD47", "B64926", "FF8427", "CC9900", "B22600", "CC9900", "666699"],
  ["Red", "windowText", "window", "323232", "E5C243", "A5300F", "D55816", "E19825", "B19C7D", "7F5F52", "B27D49", "6B9F25", "B26B02"],
  ["Red Violet", "windowText", "window", "454551", "D8D9DC", "E32D91", "C830CC", "4EA6DC", "4775E7", "8971E1", "D54773", "6B9F25", "8C8C8C"],
  ["Violet", "windowText", "window", "373545", "DCD8DC", "AD84C6", "8784C7", "5D739A", "6997AF", "84ACB6", "6F8183", "69A020", "8C8C8C"],
  ["Violet II", "windowText", "window", "632E62", "EAE5EB", "92278F", "9B57D3", "755DD9", "665EB8", "45A5ED", "5982DB", "0066FF", "666699"],
  ["Median", "windowText", "window", "775F55", "EBDDC3", "94B6D2", "DD8047", "A5AB81", "D8B25C", "7BA79D", "968C8C", "F7B615", "704404"],
  ["Paper", "windowText", "window", "444D26", "FEFAC9", "A5B592", "F3A447", "E7BC29", "D092A7", "9C85C0", "809EC2", "8E58B6", "7F6F6F"],
  ["Marquee", "windowText", "window", "5E5E5E", "DDDDDD", "418AB3", "A6B727", "F69200", "838383", "FEC306", "DF5327", "F59E00", "B2B2B2"],
  ["Slipstream", "windowText", "window", "212745", "B4DCFA", "4E67C8", "5ECCF3", "A7EA52", "5DCEAF", "FF8021", "F14124", "56C7AA", "59A8D1"],
  ["Aspect", "windowText", "window", "323232", "E3DED1", "F07F09", "9F2936", "1B587C", "4E8542", "604878", "C19859", "6B9F25", "B26B02"],
]